import { partsColors, drawImageFromColor } from './utils';
import { getProductImages, findPartDefaultImageByColor, sortByMediaTypeNames } from '@/utils/productImages';
import { mediaHasClassTypeIds } from '@/utils/media';
import { CONFIGURATION_TYPE } from '@/utils/productPrices';

const priorityConfigurationTypes = [
  CONFIGURATION_TYPE.Decorated,
  CONFIGURATION_TYPE.Blank,
  CONFIGURATION_TYPE.Sample,
  CONFIGURATION_TYPE.Box,
];

function isValidColor(strColor) {
  const s = new Option().style;
  s.color = strColor;

  // return 'false' if color wasn't assigned
  return s.color == strColor.toLowerCase();
}
import { mapGetters } from 'vuex';
import { mediaList } from '../utils/productImages';
const productViewMixin = {
  data() {
    return {
      prevTypes: [],
      productImage: '',
      subPartsLabel: '',
      selectedColor: '',
    };
  },
  methods: {
    chooseColorLocal(color, changeMain) {
      this.selectedPart = color;
      this.part.color = color.colorName;
      this.part.id = color.partId;
      if (changeMain && color.image) this.productData.imgUrl = color.image.url;
      this.chooseColor(color, changeMain);
    },
    checkProductParts() {
      try {
        const item = this.productData;
        this.productData.defaultDecoration = this.defaultDecoration;
        let types = item.parts
          .map((e) => e.pricing.map((d) => d.configurationType))
          .reduce((a, b) => [...a, ...b], [])
          .filter((type) =>
            (this.productViewModalPricing || []).length > 0 ? this.productViewModalPricing.includes(type) : true
          );
        types = [...new Set(types)].filter((e) => e);
        if (
          this.storeInformation &&
          (this.storeInformation.suppliersHideBlankPrices || []).includes(this.productData.supplierId._id)
        )
          types = types.filter((e) => e !== CONFIGURATION_TYPE.Blank);

        if (
          types.length &&
          ((!this.$route.query.configurationType && this.prevTypes.length !== types.length) ||
            !this.selectedConfigurationType ||
            !types.includes(this.selectedConfigurationType))
        ) {
          const selectedType = priorityConfigurationTypes.find((type) => types.includes(type));

          this.onSelectedConfigurationType(selectedType || types[0]);
          this.prevTypes = types;
        }
        if (item.parts && item.parts.length) {
          item.parts = [
            ...item.parts.filter((el) => el.partId || el.part_id),
            ...item.parts.filter((el) => !(el.partId || el.part_id)),
          ];
          const parts = item.parts;
          let isSameName =
            (parts.length > 1 && parts[0].partDescription && parts[0].partDescription === parts[1].partDescription) ||
            item.supplierCode === 'GOLDSTAR';

          const productImages = this.productImages;
          const partGroups = [];
          const existPartGroups = {};
          const partsGroup = {};
          for (let i = 0, l = parts.length; i < l; i++) {
            const part = parts[i];
            part.colorName = part.colors.map((color) => color.name).join('/') || part.partDescription; // || part.partId;
            part.colorNameLabel = part.partDescription || part.colors.map((color) => color.name).join('/'); // || part.partId;
            if (item.supplierCode === 'GOLDSTAR') {
              part.colorName += part.partDescription;
            }
            try {
              if (isSameName) {
                part.colorNameLabel = `${part.colors.map((color) => color.name).join('/') ||
                  part.partId} ${part.partDescription || ''}`;
              }
              part.colorNameLabel = decodeURI(part.colorNameLabel);
            } catch (e) {}
            part.colorNameLabel = part.colorNameLabel.removeSpecialChars();
            if (parts[i].partGroup && !existPartGroups[parts[i].partGroup]) {
              partGroups.push({
                partGroup: part.partGroup,
                colorNameLabel: part.colorNameLabel,
              });
              existPartGroups[parts[i].partGroup] = true;
            }

            if (!part.partGroup) part.partGroup = '1';
            if (!partsGroup[part.partGroup]) partsGroup[part.partGroup] = { count: 0, name: part.partGroupDescription };
            partsGroup[part.partGroup].count++;
          }

          const isProductView = this.$route.path.match('/products/');
          let partsOptions = [];
          try {
            partsOptions = partsColors('1', parts) || [];
            if (partsOptions?.length && isProductView) {
              partsOptions = partsColors('1', parts, isProductView); //for product view show parts without pricing if at least one part has pricing
            }
            if (!partsOptions) partsOptions = [];
          } catch (e) {
            console.log('error:', e);
            if (!partsOptions) partsOptions = [];
          }

          this.subPartsLabel = Array.from(
            new Set(parts.map((e) => e.partGroup || '1').filter((el) => parseInt(el) > 1))
          )
            .map((el) => {
              return `${partsGroup[el].count} ${partsGroup[el].name}`;
            })
            .join(', ');
          if (this.subPartsLabel) this.subPartsLabel = ', ' + this.subPartsLabel;
          partGroups.sort((a, b) => a.partGroup > b.partGroup);
          const productImagesIds = productImages.map((e) => e._id);

          parts.forEach((part) => {
            let availableParts = [];

            for (let di = 0; di < part.mediaList.length; di++) {
              if (part.mediaList[di] && productImagesIds.includes(part.mediaList[di]._id)) {
                availableParts.push(part.mediaList[di]);
              }
            }
            if (!availableParts.length) availableParts = part.mediaList.filter((e) => e && e.url);

            part.colorNameRelativeLabel = '';
            for (let i = 0; i < partGroups.length; i++) {
              if (parseInt(part.partGroup) >= parseInt(partGroups[i].partGroup)) {
                part.colorNameRelativeLabel +=
                  (part.colorNameRelativeLabel ? ' / ' : '') +
                  (part.partGroup === partGroups[i].partGroup ? part.colorNameLabel : partGroups[i].colorNameLabel);
              }
            }
            if (part.colors[0]) {
              if (part.colors[0].hex) {
                part.colors[0].hex = part.colors[0].hex.replace(/ /g, '');
                if (part.colors[0].hex.match('/')) {
                  part.colors[0].hex = part.colors[0].hex.split('/')[1];
                } else if (part.colors[0].hex.match(',')) {
                  part.colorHex = `linear-gradient(90deg, #${part.colors[0].hex
                    .split(',')[0]
                    .replace(/#/g, '')}, #${part.colors[0].hex.split(',')[1].replace(/#/g, '')})`;
                }
                if (!part.colorHex) part.colorHex = `#${part.colors[0].hex}`;
              } else if (part.colors[0].name) {
                if (part.colors[0].name.match('/')) {
                  part.colorHex = `linear-gradient(90deg, ${part.colors[0].name
                    .toLowerCase()
                    .split('/')
                    .map((e) => e)
                    .join(',')})`;
                } else {
                  part.colorHex = part.colors[0].name.split(' ')[1];
                }

                if (!part.colorHex) part.colorHex = part.colors[0].name;
                if (part.colorHex) part.colorHex = part.colorHex.toLowerCase();
                if (part.colorHex) part.colorHex = `${part.colorHex}`;
              }
            }

            const productImage = productImages
              .filter(
                (a) =>
                  (a.partId || a.part_id) === (part.partId || part.part_id) ||
                  (a.color &&
                    (part.colorName || part.colorNameLabel || part.partDescription || '')
                      .replace(/ /g, '')
                      .toLowerCase() === a.color.toLowerCase().replace(/ /g, ''))
              )
              .sort((a, b) => (a.url > b.url ? 1 : -1))[0];
            if (!availableParts.length && !productImage && part.colorHex && 0) {
              const imagePath = drawImageFromColor(
                part.colorHex,
                part.colorName || part.colorNameLabel || part.partDescription || ''
              );
              if (imagePath) {
                const img = {
                  generated: true,
                  partId: part.partId,
                  color: part.colors.map((e) => e.name).join('/'),
                  url: imagePath,
                  urlSmall: imagePath,
                  urlOrigin: imagePath,
                };
                availableParts.push(img);
                part.mediaList.push(img);
                part.image = img;
              }
            }
            let resultMatchImages = [];
            const colorName = part.colors.map((e) => e.name).join('');
            for (let i = 0; i < availableParts.length; i++) {
              if (availableParts[i].partId === part.partId) {
                resultMatchImages.push(availableParts.splice(i--, 1)[0]);
              }
            }
            for (let i = 0; i < availableParts.length; i++) {
              if (availableParts[i].color === colorName) {
                resultMatchImages.push(availableParts.splice(i--, 1)[0]);
              }
            }

            if (!part.image) {
              let list = [resultMatchImages, availableParts];
              for (let i = 0; i < list.length; i++) {
                const availableParts = sortByMediaTypeNames(
                  list[i].filter((e) => e),
                  this.storeInformation.partImagesOrder
                );
                part.image =
                  part.imageDefault ||
                  findPartDefaultImageByColor(part, parts) ||
                  availableParts.find((img) => img.partId || img.part_id) ||
                  availableParts.find((img) => img.isDefault) ||
                  productImage ||
                  availableParts.find((img) => mediaHasClassTypeIds(img, ['1002', '1007', '1006']));

                if (part.image) break;
              }

              if (!part.image) {
                part.image = availableParts[0];
              }
            } else if (part.image && typeof part.image === 'string') {
              part.image =
                part.imageDefault ||
                findPartDefaultImageByColor(part, parts) ||
                availableParts.find((i) => i._id === part.image);
            }
          });

          parts.forEach((part) => {
            if (!part.image) {
              part.image = item.imageDefault;
            }
            if (!part.image && part.colorHex) {
              const imagePath = drawImageFromColor(
                part.colorHex,
                part.colorName || part.colorNameLabel || part.partDescription || ''
              );
              if (imagePath) {
                const img = {
                  generated: true,
                  partId: part.partId,
                  color: part.colors.map((e) => e.name).join('/'),
                  url: imagePath,
                  urlSmall: imagePath,
                  urlOrigin: imagePath,
                };
                part.mediaList.push(img);
                part.image = img;
              }
            }
          });
          let firstImage = productImages[0];
          if (firstImage && !this.productImage) {
            this.productData.imgUrl = firstImage.url;
            this.productData.imgUrlSmall = firstImage.urlSmall;
            this.productImage = this.productData.imgUrl;
          }

          partsOptions.sort((a, b) =>
            a.image?.urlSmall && b.image?.urlSmall && a.image.urlSmall.length > b.image.urlSmall.length ? 1 : -1
          );
          partsOptions.sort((a, b) => a.minPrice - b.minPrice);
          if (!this.selectedPart) {
            if (this.$route.query.partId) {
              this.selectedPart = item.parts.find((i) => i.partId === this.$route.query.partId);
            } else if (partsOptions.length) {
              this.selectedPart = partsOptions.find(
                (el) =>
                  el.pricing &&
                  el.pricing.find((e) => e.price) &&
                  el.mediaList?.filter((m) => m && m.isDefault).length > 0
              );
              if (!this.selectedPart)
                this.selectedPart = partsOptions.find(
                  (el) => el.pricing && el.pricing.find((e) => e.price || e.unitPrice)
                );
              if (
                !this.selectedPart &&
                (this.storeInformation.allowNoPricing || this.productData.noPricingStore || 1)
              ) {
                this.selectedPart = partsOptions[0] || this.productData.parts[0];
              }
            }
          }

          if (!this.selectedPart && (this.storeInformation.allowNoPricing || this.productData.noPricingStore)) {
            this.selectedPart = partsOptions[0] || this.productData.parts[0];
          }
          if (this.selectedPart) this.selectedColor = this.selectedPart.colorNameRelativeLabel;
        }
      } catch (e) {
        console.log('selected erorr:', e);
      }
    },
  },
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
    documentFiles() {
      const list = mediaList({ onlyType: ['Document'], productParts: this.productData.parts }).media;
      const res = [];
      const exist = {};
      for (let i = 0; i < list.length; i++) {
        if (!exist[list[i].url]) {
          exist[list[i].url] = 1;
          res.push(list[i]);
        }
      }
      return res;
    },
    videoFiles() {
      const list = mediaList({ onlyType: ['Video', 'Audio'], productParts: this.productData.parts }).media;
      const res = [];
      const exist = {};
      for (let i = 0; i < list.length; i++) {
        if (list[i].url && !exist[list[i].url]) {
          exist[list[i].url] = 1;
          if (!/\.(jpeg|jpg|png)$/.test(list[i].url)) {
            res.push(list[i]);
          }
        }
      }
      return res;
    },
    noPricing() {
      return (
        this.productData.noPricing ||
        !this.selectedPart ||
        !this.selectedPart.pricing ||
        !this.selectedPart.pricing.find((e) => e.price || e.unitPrice)
      );
    },
    productImages() {
      const options = {
        ...(this.productData.supplierId || {}),
        mediaTypePriority: this.storeInformation.productImagesOrder,
      };
      let productImage;
      if (!this.productData.parts.find((e) => e.mediaList?.length)) {
        productImage = this.productData.imageDefault;
      }
      let parts = this.productData.parts;
      if (this.part?.colorName) {
        const _parts = this.productData.parts.filter((e) => e.colorName === this.part.colorName);
        if (_parts.find((e) => e.mediaList?.length || e.image?._id)) {
          parts = _parts;
        }
      }
      let images = getProductImages(parts, productImage, true, options);
      if (this.selectedPart && this.productView?.showOnlyPartImages) {
        const parts = (this.productData.parts || [])
          .filter((p) => p.colorName === this.selectedPart.colorName)
          .map((p) => p._id);
        images = images.filter((i) => parts.includes(i.productPart));
      }
      return images;
    },
  },
};

export { productViewMixin as default };
