import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue from 'vue';
import { mapGetters } from 'vuex';

const recaptchaImportMixin = {
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
  },
  created() {
    Vue.use(VueReCaptcha, {
      siteKey: this.storeInformation.reCaptcha?.siteKey,
    });
  },
  methods: {
    async getReCaptchaToken() {
      const isDev = ['local', 'development'].includes(process.env.NODE_ENV);
      if (isDev) {
        return '';
      } else {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('login');
      }
    },
  },
};

export { recaptchaImportMixin as default };
